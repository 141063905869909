<template>
  <div class="public-page-body">
    <div class="public-card-1">
      <div class="card-head">
        <router-link class="card-head-title" :to="goBackUrl()">
          <i class="title-arrow el-icon-arrow-left"></i>
          <h3 class="title-text">
            {{
          linkParams.id
            ? "编辑" + linkTypeName + "环节"
            : "添加" + linkTypeName + "环节"
        }}
          </h3>
          <i class="title-icon" :class="`title-icon-${this.linkTypeId}`"></i>
        </router-link>
      </div>
      <el-form :model="linkParams" :rules="linkParamsRules" ref="linkForm" label-width="170px" class="link-el-form">
        <el-form-item label="环节名称：" prop="name">
          <el-input v-model="linkParams.name" placeholder="请输入环节名称" style="width: 400px"></el-input>
        </el-form-item>

        <el-form-item label="开展方式：" prop="implement_type">
          <el-radio-group v-model="linkParams.implement_type">
            <el-radio :label="item.value" v-for="(item, index) in modeOption" :key="index">
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="资源数量：" prop="resourcesAmount" v-if="linkTypeId == 2">
          <div class="fc">
            <div class="fc mr20">
              <span class="mr6">最少个数</span>
              <el-input-number v-model="linkParams.resources_min" controls-position="right" :min="1"
                :max="linkParams.resources_max" size="small"></el-input-number>
            </div>
            <div class="fc">
              <span class="mr6">最大个数</span>
              <el-input-number v-model="linkParams.resources_max" controls-position="right"
                :min="linkParams.resources_min" size="small"></el-input-number>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="资源类型：" prop="resources_type" class="resourcesType-form-item" v-if="linkTypeId == 2">
          <el-checkbox-group v-model="linkParams.resources_type">
            <el-checkbox label="1">音视频</el-checkbox>
            <el-checkbox label="2">图片</el-checkbox>
            <el-checkbox label="3">word</el-checkbox>
            <el-checkbox label="4">pdf</el-checkbox>
            <el-checkbox label="5">excel</el-checkbox>
            <el-checkbox label="6">ppt</el-checkbox>
          </el-checkbox-group>
        </el-form-item>

        <!-- <el-form-item label="起止时间：" prop="linkTime" v-if="linkTypeId == 7">
          <el-date-picker v-model="linkParams.linkTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item> -->

        <!-- <el-form-item label="起止时间：" prop="linkTime" v-if="linkTypeId == 7 || linkTypeId == 8">
          <el-date-picker v-model="linkParams.start_date" type="date" :placeholder="`请选择${this.liveORMeetingText}时间`" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss"
            style="width: 200px; margin-right: 10px">
          </el-date-picker>
          <el-time-picker type="fixed-time" placeholder="开始时间" v-model="linkParams.start_time" value-format="HH:mm" format="HH:mm"
            style="width: 120px; margin-right: 5px" @change="change_start"></el-time-picker>
          <span>- </span>

          <el-time-picker type="fixed-time" placeholder="结束时间" v-model="linkParams.end_time" value-format="HH:mm" format="HH:mm"
            style="width: 120px; margin-right: 5px"></el-time-picker>
        </el-form-item> -->

        <el-form-item label="起止时间：" prop="linkTime">
          <div v-if="linkTypeId == 7 || linkTypeId == 8">
            <el-date-picker v-model="linkParams.start_date" type="date" :placeholder="`请选择${this.liveORMeetingText}时间`"
              format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd HH:mm:ss" style="width: 200px; margin-right: 10px">
            </el-date-picker>
            <el-time-picker type="fixed-time" placeholder="开始时间" v-model="linkParams.start_time" value-format="HH:mm"
              format="HH:mm" style="width: 120px; margin-right: 5px" @change="change_start"></el-time-picker>
            <span>- </span>

            <el-time-picker type="fixed-time" placeholder="结束时间" v-model="linkParams.end_time" value-format="HH:mm"
              format="HH:mm" style="width: 120px; margin-right: 5px"></el-time-picker>
          </div>
          <el-date-picker v-model="linkParams.linkTime" type="datetimerange" value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" v-else>
          </el-date-picker>
        </el-form-item>

        <el-form-item label="添加资源：" prop="uploadResourcesFileList"
          v-if="linkParams.implement_type == 1 && linkTypeId == 1">
          <new-addresource :ifsingle="false" :selectValue="linkParams.uploadResourcesFileList"
            @ouput-value="uploadResourcesSuccess"></new-addresource>
          <ul class="resource-list" v-if="linkParams.uploadResourcesFileList.length > 0">
            <li v-for="(item, index) in linkParams.uploadResourcesFileList" :key="index">
              <i class="el-icon-close" @click="delResourceList(index)"></i>
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </el-form-item>

        <el-form-item label="添加问卷：" prop="questionnaire_id" v-if="linkParams.implement_type == 1 && linkTypeId == 4">
          <div class="form-item-flex">
            <div class="item-flex-left">
              <el-select v-model="linkParams.questionnaire_id" filterable placeholder="请选择问卷"
                v-el-select-loadmore="questionnaireLoadmore" v-el-select-input="questionnaireKeyword"
                @focus="questionnaireFocus()">
                <el-option v-for="(item, index) in questionnaireOption" :key="index" :label="item.title"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="item-flex-right">
              <p class="item-text">
                没有问卷？
                <span class="item-link" @click="linkButton(1)">创建问卷>></span>
              </p>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="添加投票：" prop="questionnaire_id" v-if="linkParams.implement_type == 1 && linkTypeId == 5">
          <div class="form-item-flex">
            <div class="item-flex-left">
              <el-select v-model="linkParams.questionnaire_id" filterable placeholder="请选择投票"
                v-el-select-loadmore="voteLoadmore" v-el-select-input="voteKeyword" @focus="voteFocus()">
                <el-option v-for="(item, index) in voteOption" :key="index" :label="item.title" :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="item-flex-right">
              <p class="item-text">
                没有投票？
                <span class="item-link" @click="linkButton(2)">创建投票>></span>
              </p>
            </div>
          </div>
        </el-form-item>

        <el-form-item label="是否公开结果：" prop="open"
          v-if="linkParams.implement_type == 1 && (linkTypeId == 4 || linkTypeId == 5)">
          <el-radio-group v-model="linkParams.open">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="2">否</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="环节描述：" prop="description">
          <div class="pr80">
            <Editor v-model="linkParams.description"></Editor>
          </div>
        </el-form-item>

        <div v-if="linkTypeId == 7 || linkTypeId == 8">
          <el-form-item :label="`${this.liveORMeetingText}封面：`" prop="live_cover_id">
            <div class="upload-box2">
              <template v-if="!linkParams.liveCover && !linkParams.live_cover_id">
                <div :class="index == uploadImgDefaultIndex ? 'box_img1' : 'box_img2'"
                  @click="uploadImgDefaultChange(index, item)" v-for="(item, index) in uploadImgDefaultList"
                  :key="index">
                  <img :src="item.url" alt="" width="134px" height="75px" />
                  <i class="el-icon-check dg"></i>
                </div>
              </template>
              <dc-upload style="position: relative" ref="liveCoverUpload" :dcAutoUpload="true" :show-file-list="false"
                dcdom="liveCoverUpload" :dcConfig="dcUploadConfig" @dc-success="liveCoverSuccess"
                @dc-progress="dcProgressTrue = false" v-if="dcUploadConfig.key && dcUploadConfig.notify_url">
                <div class="upload-img-box">
                  <img :src="linkParams.liveCover" v-if="linkParams.live_cover_id" class="upload-img" />
                  <i class="el-icon-plus upload-noData" v-else></i>
                </div>
              </dc-upload>
            </div>
          </el-form-item>

          <el-form-item :label="`${this.liveORMeetingText}主题：`" prop="live_title">
            <el-input v-model="linkParams.live_title" :placeholder="`请输入${this.liveORMeetingText}主题`"
              style="width: 400px"></el-input>
          </el-form-item>

          <el-form-item label="主持人：" prop="anchor_user_id">
            <el-select v-model="linkParams.anchor_user_id" filterable placeholder="请选择主持人">
              <el-option v-for="(item, index) in teachingGroupMemberList" :key="index" :label="item.username"
                :value="item.user_id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item :label="`${this.liveORMeetingText}方式：`" prop="live_type">
            <el-select v-model="linkParams.live_type" filterable :placeholder="`请选择${this.liveORMeetingText}方式`"
              :disabled="linkTypeId == 8">
              <el-option v-for="(item, index) in liveTypeOption" :key="index" :label="item.name"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="回放设置：" prop="playbackSetup"
            v-if="linkParams.live_type == 1 || linkParams.live_type == 3">
            <div class="flex_align_center">
              <div>
                <el-select v-model="linkParams.live_upload" filterable placeholder="请选择回放设置"
                  @change="playbackOptionChange">
                  <el-option v-for="(item, index) in playbackOption" :key="index" :label="item.name"
                    :value="item.value"></el-option>
                </el-select>
              </div>
              <div class="flex_align_center ellipsis ml20" v-if="linkParams.live_upload == 1">
                {{ liveORMeetingText }}结束后
                <el-input v-model="linkParams.live_upload_time" placeholder="请输入" style="width: 80px"
                  class="ml15 mr15"></el-input>
                分钟后自动上传
              </div>
            </div>
          </el-form-item>

          <el-form-item label="录播视频 :" prop="timeFn3" v-if="linkParams.live_type == 2">
            <dc-upload v-if="!resource_video_data.length &&
          dcUploadConfig.key &&
          dcUploadConfig.notify_url
          " class="upload-demo" ref="upload" :dcAutoUpload="true" :limit="1" dcdom="ceshi01"
              :dcConfig="dcUploadConfig" @dc-success="dcFile" accept=".mp4,.MP4">
              <el-button slot="trigger" size="small" type="primary">
                选取视频文件
              </el-button>
              <div slot="tip" class="el-upload__tip">
                <span style="color: red">*</span>
                <span>请上传mp4格式视频，限制1个</span>
              </div>
            </dc-upload>
            <div class="videoBox" v-if="resource_video_data.length">
              <div class="forItemBox" v-for="(item, index) in resource_video_data" :key="index">
                <div class="one ellipsis_1">{{ item.resource_name }}</div>
                <div class="two"><span @click="clearVideo()">删除</span></div>
              </div>
            </div>
          </el-form-item>

          <!-- <el-form-item
            :label="`${liveORMeetingText}参与者：`"
            prop="permiss_range"
            class="permiss"
          >
            <el-radio-group v-model="linkParams.permiss_range">
              <el-radio :label="1">所有人</el-radio>
              <el-radio :label="4">自定义</el-radio>
            </el-radio-group>
            <div class="participation-box ml20" v-if="linkParams.permiss_range == 4">
              <div class="box-left">
                <el-button size="mini" @click="meetingTearmTableShow = true">
                  选择成员
                </el-button>
              </div>
              <div class="box-right">
                <p class="participation-people" @click="meetingTearmTableShow = true">
                  已选
                  <span>
                    {{ meetingTearmTableChoiceNumber }}
                  </span>
                  人>>
                </p>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="允许其他人报名：" prop="sign_up">
            <el-radio-group v-model="linkParams.sign_up">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="2">关闭</el-radio>
            </el-radio-group>
          </el-form-item> -->
        </div>

        <!-- <div v-if="linkParams.implement_type == 1 && linkTypeId == 8">
          <el-form-item label="主题封面：">
            <dc-upload ref="meetingCoverUpload" :dcAutoUpload="true" :show-file-list="false" dcdom="meetingCoverUpload" :dcConfig="dcUploadConfig"
              @dc-success="meetingCoverSuccess" @dc-progress="dcProgressTrue = false" v-if="dcUploadConfig.key && dcUploadConfig.notify_url">
              <div class="upload-box">
                <img :src="linkParams.meetingCover" v-if="linkParams.meeting_cover_id" class="upload-img" />
                <i class="el-icon-plus upload-noData" v-else></i>
              </div>
            </dc-upload>
          </el-form-item>

          <el-form-item label="会议主题：" prop="meeting_title">
            <el-input v-model="linkParams.meeting_title" placeholder="请输入会议主题" style="width: 400px"></el-input>
          </el-form-item>

          <el-form-item label="参与人员：" prop="user_ids">
            <div class="participation-box">
              <div class="box-left">
                <el-button size="mini" @click="meetingTearmTableShow = true">
                  选择成员
                </el-button>
              </div>
              <div class="box-right">
                <p class="participation-people" @click="meetingTearmTableShow = true">
                  已选<span>{{ meetingTearmTableChoiceNumber }}</span>人>>
                </p>
              </div>
            </div>
            <!-- <div class="tearm-box">
              <div class="box-operate">
                <el-button
                  type="primary"
                  size="mini"
                  style="margin-bottom: 15px"
                  @click="meetingTearmTableShow = true"
                >
                  选择指定成员
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  @click="meetingTearmAllDel()"
                  style="margin-left: 0"
                >
                  清空所有
                </el-button>
              </div>
              <div class="box-tag">
                <el-tag
                  :key="index"
                  v-for="(item, index) in meetingTearmCheckedList"
                  closable
                  @close="meetingTearmDel(item)"
                  style="margin-right: 10px"
                >
                  {{ item.realname }}
                </el-tag>
              </div>
            </div> --
          </el-form-item>
        </div> -->

        <template v-if="linkParams.type == 6">
          <el-form-item v-if="false" label="文档类型：" prop="doc_type">
            <el-radio-group v-model="linkParams.doc_type">
              <el-radio label="sheet">在线表格</el-radio>
              <el-radio label="doc">在线文档</el-radio>
              <el-radio label="slide">在线幻灯片</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="主责人：" prop="anchor_user_id">
            <el-select v-model="linkParams.anchor_user_id" filterable placeholder="请选主责人">
              <el-option v-for="(item, index) in personOption" :key="index" :label="item.realname" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="文档标题：" prop="doc_title">
            <el-input v-model="linkParams.doc_title" placeholder="请输入文档标题" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item label="初始时间：" prop="initial_time">
            <el-date-picker v-model="linkParams.initial_time" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
              placeholder="请选择初始时间">
            </el-date-picker>
          </el-form-item>
        </template>
      </el-form>

      <div class="bottom-button-box">
        <router-link class="button-cancel mr60" :to="goBackUrl()">取消</router-link>
        <span class="button-confirm" @click="submitButton()">
          提交
          <span class="temp_class_span_div">-783</span>
        </span>
      </div>
      <!--选择指定成员弹窗-->
      <UserSelectTable v-if="meetingTearmTableShow" :isShowTable="meetingTearmTableShow"
        :tearmCheckedList="meetingTearmCheckedList" :group_id="activityInfo.group_id"
        :teaching_studio_id="activityInfo.teaching_studio_id" :is_personid="activityInfo.is_personid"
        :isAppointMember="true" :appointMember="activityInfo.permiss_users" @confirmEvent="meetingTearmTableChecked">
      </UserSelectTable>
      <!--选择指定成员弹窗-->
    </div>
  </div>
</template>

<script>
import { queryString } from "@/utils/utils.js";
import UserSelectTable from "../components/userSelectTable.vue";
import * as researchApi from "@/api/researchApi.js";
export default {
  components: {
    UserSelectTable,
  },
  props: {},
  data() {
    const validateDatetimerange = (rule, value, callback) => {
      if (
        this.linkTypeId == 7 &&
        (this.linkParams.start_date == "" ||
          this.linkParams.start_time == "" ||
          this.linkParams.end_time == "")
      ) {
        callback(new Error("请选择直播时间"));
      } else if (
        this.linkTypeId == 8 &&
        (this.linkParams.start_date == "" ||
          this.linkParams.start_time == "" ||
          this.linkParams.end_time == "")
      ) {
        callback(new Error("请选择会议时间"));
      } else if (
        this.linkTypeId != 7 &&
        this.linkParams.linkTime.length == 0 &&
        (this.linkParams.start_date == "" ||
          this.linkParams.start_time == "" ||
          this.linkParams.end_time == "")
      ) {
        callback(new Error("请选择起止时间"));
      } else {
        callback();
      }
    };
    const validateUploadResourcesFileList = (rule, value, callback) => {
      if (this.linkParams.resources.length == 0) {
        callback(new Error("请上传资源"));
      } else {
        callback();
      }
    };
    const validateUploadLiveCover = (rule, value, callback) => {
      callback();
    };
    const validateUploadMeetingCover = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请上传会议主题封面"));
      } else {
        callback();
      }
    };
    const validateUserids = (rule, value, callback) => {
      if (this.meetingTearmCheckedList.length == 0) {
        callback(new Error("请选择参与人员"));
      } else {
        callback();
      }
    };
    const validatePlaybackSetup = (rule, value, callback) => {
      console.log(this.linkParams.live_upload);
      if (this.linkParams.live_upload == "") {
        callback(new Error("请选择回放设置"));
      } else if (
        this.linkParams.live_upload == 1 &&
        this.linkParams.live_upload_time == ""
      ) {
        callback(new Error("请输入上传时间"));
      } else {
        callback();
      }
    };
    const validateResourcesAmount = (rule, value, callback) => {
      if (this.linkParams.resources_min == 0 || this.linkParams.resources_max == 0) {
        callback(new Error("请设置资源数量"));
      } else {
        callback();
      }
    };
    const validateQuestionnaire = (rule, value, callback) => {
      if (this.linkTypeId == 4 && !this.linkParams.questionnaire_id) {
        callback(new Error("请选择问卷"));
      } else if (this.linkTypeId == 5 && !this.linkParams.questionnaire_id) {
        callback(new Error("请选择投票"));
      } else {
        callback();
      }
    };
    const validateAnchor = (rule, value, callback) => {
      if (
        (this.linkTypeId == 7 || this.linkTypeId == 8) &&
        !this.linkParams.anchor_user_id
      ) {
        callback(new Error("请选择主持人"));
      } else if (this.linkTypeId == 6 && !this.linkParams.anchor_user_id) {
        callback(new Error("请选择主责人"));
      } else {
        callback();
      }
    };
    const verifyFn_3 = (rule, value, callback) => {
      if (!this.resource_video_data || !this.resource_video_data.length) {
        callback(new Error("请选择上传视频"));
      } else {
        callback();
      }
    };
    return {
      //金山云上传配置
      dcUploadConfig: {
        key: "",
        notify_url: "",
      },
      //资源上传限制格式
      uploadResourcesFormat: [
        "jpg",
        "jpeg",
        "png",
        "epub",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "pdf",
        "mp4",
        "mp3",
      ],
      //开展方式选项
      modeOption: [
        {
          name: "线上",
          value: 1,
        },
        {
          name: "线下",
          value: 2,
        },
      ],
      liveTypeOption: [],
      playbackOption: [
        {
          name: "自动上传",
          value: 1,
        },
        {
          name: "手动上传",
          value: 2,
        },
      ],
      questionnaireOption: [], //问卷选项
      voteOption: [], //投票选项
      personOption: [], // 主责人
      //问卷参数
      questionnaireParams: {
        total: 0,
        limit: 10,
        page: 1,
        keyword: "",
        search_type: 1,
      },
      //投票参数
      voteParams: {
        total: 0,
        limit: 10,
        page: 1,
        keyword: "",
        search_type: 2,
      },
      //环节参数
      linkParams: {
        liveCover: "",
        meetingCover: "",
        id: "",
        linkTime: [],
        uploadResourcesFileList: [],
        activityId: "",
        type: "",
        name: "",
        implement_type: "",
        start_date: "",
        end_date: "",
        description: "",
        resources: [],
        questionnaire_id: "",
        live_cover_id: "",
        live_title: "",
        meeting_cover_id: "",
        meeting_title: "",
        user_ids: [],
        doc_type: "",
        doc_title: "",

        anchor_user_id: "",
        initial_time: "",
        live_type: "",
        live_upload: "",
        live_upload_time: "",
        permiss_range: 1,
        sign_up: 2,
        start_time: "",
        end_time: "",
        resources_min: 1,
        resources_max: 1,
        resources_type: [],
        open: 2,
        is_personid: "",
        activity_chapter_id: 0,
      },
      //环节参数验证
      linkParamsRules: {
        doc_type: [
          {
            required: true,
            message: "请选择文档类型",
            trigger: "change",
          },
        ],
        doc_title: [
          {
            required: true,
            message: "请输入文档标题",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入环节名称",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        implement_type: [
          {
            required: true,
            message: "请选择环节开展方式",
            trigger: "change",
          },
        ],
        initial_time: [
          {
            required: true,
            message: "请选择初始时间",
            trigger: "change",
          },
        ],
        linkTime: [
          {
            required: true,
            validator: validateDatetimerange,
            trigger: "blur",
          },
        ],
        uploadResourcesFileList: [
          {
            required: true,
            validator: validateUploadResourcesFileList,
            trigger: "blur",
          },
        ],
        live_cover_id: [
          {
            required: true,
            validator: validateUploadLiveCover,
            trigger: "blur",
          },
        ],
        live_title: [
          {
            required: true,
            message: "请输入主题",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        meeting_cover_id: [
          {
            required: true,
            validator: validateUploadMeetingCover,
            trigger: "blur",
          },
        ],
        meeting_title: [
          {
            required: true,
            message: "请输入会议主题",
            trigger: "blur",
          },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        user_ids: [
          {
            required: true,
            validator: validateUserids,
            trigger: "change",
          },
        ],
        questionnaire_id: [
          {
            required: true,
            validator: validateQuestionnaire,
            trigger: "change",
          },
        ],
        description: [
          {
            required: true,
            message: "请输入环节描述",
            trigger: "blur",
          },
        ],
        anchor_user_id: [
          {
            required: true,
            validator: validateAnchor,
            trigger: "change",
          },
        ],
        live_type: [
          {
            required: true,
            message: "请选择方式",
            trigger: "change",
          },
        ],
        live_upload: [
          {
            required: true,
            message: "请选择回放设置",
            trigger: "change",
          },
        ],
        live_upload_time: [
          {
            required: true,
            message: "请设置上传时间",
            trigger: "change",
          },
        ],
        permiss_range: [
          {
            required: true,
            message: "请选择允许谁参与",
            trigger: "change",
          },
        ],
        sign_up: [
          {
            required: true,
            message: "请选择允许其他人报名",
            trigger: "change",
          },
        ],
        playbackSetup: [
          {
            required: true,
            validator: validatePlaybackSetup,
            trigger: "blur",
          },
        ],
        resourcesAmount: [
          {
            required: true,
            validator: validateResourcesAmount,
            trigger: "blur",
          },
        ],
        resources_type: [
          {
            required: true,
            message: "请选择资源类型",
            trigger: "change",
          },
        ],
        open: [
          {
            required: true,
            message: "请选择是否公开结果",
            trigger: "change",
          },
        ],
        timeFn3: [{ required: true, validator: verifyFn_3, trigger: "change" }],
      },
      meetingTearmTableShow: false, //教研会议指定成员表格弹窗
      meetingTearmCheckedList: [], //教研会议指定成员列表
      meetingTearmTableChoiceNumber: 0, //教研会议指定成员选中数量
      uploadImgDefaultList: [], //默认封面列表
      uploadImgDefaultIndex: 0, //默认封面索引
      //默认封面选中参数
      uploadImgDefault: {
        url: "",
        id: 0,
      },
      teachingGroupMemberList: [],
      liveORMeetingText: "直播",
      // 视频数据
      resource_video_data: [],
      //环节类型id
      linkTypeId: 0,
      //环节id
      linkId: 0,
      //活动信息详情
      activityInfo: {},
      //环节类型名称
      linkTypeName: "",
    };
  },
  created() {
    const query = this.$route.query;
    this.getDcUploadConfig(); //获取金山云配置
    this.getDefaultCover();
    this.linkParams.activityId = query.activityId;
    this.linkTypeId = query.linkTypeId;
    this.linkId = query.linkId || 0;
    this.linkParams.is_personid = query.is_from;
    this.activityInfo.is_personid = query.is_from;
    this.linkParams.activity_chapter_id = query.chapterId;
    this.linkTypeName = decodeURIComponent(query.linkTypeName);
    this.linkParams.type = this.linkTypeId;

    const modeType = sessionStorage.getItem("research_activity_mode_type"); //开展方式选项
    if (modeType == 1) {
      this.modeOption = [
        {
          name: "线上",
          value: 1,
        },
      ];
    }
    if (modeType == 2) {
      this.modeOption = [
        {
          name: "线下",
          value: 2,
        },
      ];
    }
    if (modeType == 3) {
      this.modeOption = [
        {
          name: "线上",
          value: 1,
        },
        {
          name: "线下",
          value: 2,
        },
      ];
    }
    this.linkParams.implement_type = Number(modeType);
  },
  mounted() {
    this.getActivityInfo();
    if (this.linkId) {
      this.getLinkDetails(this.linkId);
    }
  },
  methods: {
    //获取活动信息
    async getActivityInfo() {
      const res = await this.$axios.get(
        `/activity/edit/${this.linkParams.activityId}?is_personid=${this.linkParams.is_personid}`
      );
      const data = res.data.data;
      this.activityInfo.activityInfoCover = data.cover_url;
      this.activityInfo.activityInfoTime = [
        `${data.start_date} ${data.start_time}`,
        `${data.end_date} ${data.end_time}`,
      ];
      this.activityInfo.cover_id = data.cover_id;
      this.activityInfo.name = data.name;
      this.activityInfo.label = data.label;
      this.activityInfo.description = data.description;
      this.activityInfo.stage_id = data.stage_id ? data.stage_id : "";
      this.activityInfo.subject_id = data.subject_id ? data.subject_id : "";
      this.activityInfo.implement_type = data.implement_type;
      this.activityInfo.is_time_range = data.is_time_range;
      this.activityInfo.start_date = data.start_date;
      this.activityInfo.start_time = data.start_time;
      this.activityInfo.end_date = data.end_date;
      this.activityInfo.end_time = data.end_time;
      this.activityInfo.initiator_type = data.initiator_type;
      this.activityInfo.permiss_range = data.permiss_range;
      this.activityInfo.sign_up = data.sign_up;
      this.activityInfo.permiss_groups =
        typeof data.permiss_groups != "string" ? data.permiss_groups : [];
      this.activityInfo.group_id = data.group_id ? data.group_id : 0;
      this.activityInfo.teaching_studio_id = data.teaching_studio_id
        ? data.teaching_studio_id
        : 0;

      this.getAppointUser();

      if (this.linkTypeId == 4) {
        //问卷
        this.getQuestionnaire();
      }
      if (this.linkTypeId == 5) {
        //投票
        this.getVote();
      }
      if (this.linkTypeId == 7) {
        this.getApiTeachingGroupMember();
        this.liveORMeetingText = "直播";
        this.liveTypeOption = [
          {
            name: "常规直播",
            value: 1,
          },
          {
            name: "播放录像",
            value: 2,
          },
        ];
      }
      if (this.linkTypeId == 8) {
        this.linkParams.live_type = 3;
        this.getApiTeachingGroupMember();
        this.liveORMeetingText = "会议";
        this.liveTypeOption = [
          {
            name: "在线会议",
            value: 3,
          },
        ];
      }
    },
    //获取默认封面图
    async getDefaultCover() {
      const res = await this.$axios.get("/default/activityCover");
      this.uploadImgDefaultList = res.data.data;
      this.uploadImgDefault.id = this.uploadImgDefaultList[0].id;
      this.uploadImgDefault.url = this.uploadImgDefaultList[0].url;
    },
    //默认封面选择回调
    uploadImgDefaultChange(index, item) {
      this.uploadImgDefaultIndex = index;
      this.uploadImgDefault.url = item.url;
      this.uploadImgDefault.id = item.id;
    },
    //获取金山云配置
    async getDcUploadConfig() {
      const res = await this.$axios.get("/k3sSetting");
      const data = res.data.data;
      this.dcUploadConfig.key = data.key;
      this.dcUploadConfig.notify_url = data.callBack;
    },
    //获取环节详情
    async getLinkDetails(id) {
      const res = await this.$axios.get(`/activityLink/${id}`, {
        params: {
          is_personid: this.activityInfo.is_personid,
        },
      });
      const data = res.data.data;
      this.linkParams.id = data.id;
      if (this.linkTypeId != 7 && this.linkTypeId != 8) {
        if (data.start_date && data.end_date) {
          this.linkParams.linkTime = [`${data.start_date}`, `${data.end_date}`];
        }
      }
      this.linkParams.name = data.name;
      this.linkParams.implement_type = data.implement_type;
      this.linkParams.start_date = data.start_date;
      this.linkParams.end_date = data.end_date;
      this.linkParams.description = data.description;
      this.linkParams.questionnaire_id = data.questionnaire_id
        ? data.questionnaire_id
        : "";
      this.linkParams.liveCover = data.live_cover_url ? data.live_cover_url : "";
      this.linkParams.live_cover_id = data.live_cover_id ? data.live_cover_id : "";
      this.linkParams.live_title = data.live_title ? data.live_title : "";
      this.linkParams.meetingCover = data.meeting_cover_url ? data.meeting_cover_url : "";
      this.linkParams.meeting_cover_id = data.meeting_cover_id
        ? data.meeting_cover_id
        : "";
      this.linkParams.meeting_title = data.meeting_title ? data.meeting_title : "";
      this.$set(this.linkParams, "doc_type", data.doc_type || "");
      this.$set(this.linkParams, "doc_title", data.doc_title || "");

      this.linkParams.anchor_user_id = data.anchor_user_id ? data.anchor_user_id : "";
      this.linkParams.initial_time = data.initial_time || "";
      this.linkParams.start_time = data.start_time ? data.start_time : "";
      this.linkParams.end_time = data.end_time ? data.end_time : "";
      this.linkParams.live_type = data.live_type;
      this.linkParams.live_upload = data.live_upload ? data.live_upload : "";
      this.linkParams.live_upload_time = data.live_upload_time
        ? data.live_upload_time
        : "";
      this.linkParams.permiss_range = data.permiss_range ? Number(data.permiss_range) : 1;
      this.linkParams.sign_up = data.sign_up ? data.sign_up : 1;
      this.linkParams.resources_min = data.resources_min;
      this.linkParams.resources_max = data.resources_max;
      this.linkParams.resources_type = data.resources_type;
      this.linkParams.open = data.open;
      this.linkParams.activity_chapter_id = data.activity_chapter_id;

      this.meetingTearmCheckedList = data.appoint_user ? data.appoint_user : [];
      this.meetingTearmTableChoiceNumber = this.meetingTearmCheckedList.length;

      if (data.resources) {
        data.resources.forEach((item) => {
          this.linkParams.resources.push(item.resource_id);
          this.linkParams.uploadResourcesFileList.push({
            id: item.resource_id,
            name: item.resource_name,
            url: item.resource_url,
          });
        });
      }
    },
    //问卷/投票 创建跳转（1.问卷 2.投票）
    linkButton(type) {
      const routeData = this.$router.resolve({
        path: "/createProblem",
        query: {
          type: type,
        },
      });
      window.open(routeData.href, "_blank");
    },
    //问卷下拉获得焦点
    questionnaireFocus() {
      this.questionnaireParams.keyword = "";
      this.questionnaireParams.page = 1;
      this.getQuestionnaire("new");
    },
    //投票下拉获得焦点
    voteFocus() {
      this.voteParams.keyword = "";
      this.voteParams.page = 1;
      this.getVote("new");
    },
    //问卷下拉输入关键字回调
    questionnaireKeyword(val) {
      this.questionnaireParams.keyword = val;
      this.questionnaireParams.page = 1;
      this.getQuestionnaire("new");
    },
    //投票下拉输入关键字回调
    voteKeyword(val) {
      this.voteParams.keyword = val;
      this.voteParams.page = 1;
      this.getVote("new");
    },
    //获取问卷
    async getQuestionnaire(type) {
      const res = await this.$axios.get("/questionnaire/get_my_list", {
        params: this.questionnaireParams,
      });
      const data = res.data;
      this.questionnaireParams.total = data.data.total;
      if (type == "new") {
        this.questionnaireOption = data.data.data;
      } else {
        data.data.data.forEach((res) => {
          this.questionnaireOption.push(res);
        });
      }
    },
    //获取投票
    async getVote(type) {
      const res = await this.$axios.get("/questionnaire/get_my_list", {
        params: this.voteParams,
      });
      const data = res.data;
      this.voteParams.total = data.data.total;
      if (type == "new") {
        this.voteOption = data.data.data;
      } else {
        data.data.data.forEach((res) => {
          this.voteOption.push(res);
        });
      }
    },
    // 获取指定成员
    async getAppointUser(type) {
      if (this.activityInfo.is_personid == 1) {
        let res = await this.$axios.get("/get_appoint_user", {
          params: {
            limit: 99999,
            page: 1,
            group_id: this.activityInfo.group_id,
          },
        });
        this.personOption = res.data.data.data;
      }
      if (this.activityInfo.is_personid == 2) {
        let res = await this.$axios.get("/get_appoint_user_teachingstudio", {
          params: {
            limit: 99999,
            page: 1,
            teaching_studio_id: this.activityInfo.teaching_studio_id,
          },
        });
        this.personOption = res.data.data.data;
      }
    },
    //添加环节
    async postLink() {
      // if (this.linkParams.type == 6) { // 腾讯文档登录验证
      //   let locationObj = window.location.origin;
      //   let locationHref = `${locationObj}/#/pageAutoClose`;
      //   try {
      //     let userId = window.localStorage.getItem("id");
      //     await researchApi.getUser_tencent_docs_login({ user_id: userId });
      //   } catch (err) {
      //     if (err?.data?.data?.jump_url) {
      //       let skipSrc = `${err.data.data.jump_url}&skip_url=${encodeURIComponent(locationHref)}`;
      //       window.open(skipSrc, "_blank");
      //       return false;
      //     }
      //   }
      // };

      if (this.linkTypeId != 7 && this.linkTypeId != 8) {
        this.linkParams.start_date = this.linkParams.linkTime[0];
        this.linkParams.end_date = this.linkParams.linkTime[1];
      }
      if (this.linkTypeId == 7 || this.linkTypeId == 8) {
        const start_date = this.linkParams.start_date.split(" ")[0];
        this.linkParams.linkTime = [
          `${start_date} ${this.linkParams.start_time}:00`,
          `${start_date} ${this.linkParams.end_time}:00`,
        ];
        this.linkParams.start_date = this.linkParams.linkTime[0];
        this.linkParams.end_date = this.linkParams.linkTime[1];
      }

      this.linkParams.user_ids = this.meetingTearmCheckedList;
      this.linkParams.liveCover = this.linkParams.liveCover
        ? this.linkParams.liveCover
        : this.uploadImgDefault.url;
      this.linkParams.live_cover_id = this.linkParams.live_cover_id
        ? this.linkParams.live_cover_id
        : this.uploadImgDefault.id;
      let res = await this.$axios.post("/activityLink", this.linkParams, {
        params: {
          is_personid: this.activityInfo.is_personid,
        },
      });
      let data = res.data;
      if (data.code == 900) {
        this.$message({
          message: this.linkParams.id ? "修改成功" : "添加成功",
          type: "success",
        });
        if (this.linkParams.id) {
          //修改环节，首先删除原来的环节。
          await this.$axios.delete(`/activityLink/${this.linkParams.id}`, {
            params: {
              is_personid: this.activityInfo.is_personid,
            },
          });
        }
        this.linkParams.id = data.data.id;
        this.$router.push({
          path: this.goBackUrl(),
          query: {},
        });
      }
    },
    //资源上传成功后的回调
    uploadResourcesSuccess(list) {
      this.linkParams.resources = [];
      this.linkParams.uploadResourcesFileList = [];
      if (list.length > 0) {
        list.forEach((res) => {
          this.linkParams.resources.push(res.id);
          this.linkParams.uploadResourcesFileList.push({
            id: res.id,
            name: res.title,
            url: res.url,
          });
        });
        this.$refs["linkForm"].validateField("uploadResourcesFileList");
      }
    },
    //删除上传资源列表
    delResourceList(index) {
      this.$confirm("此操作将删除该资源, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.linkParams.resources.splice(index, 1);
          this.linkParams.uploadResourcesFileList.splice(index, 1);
        })
        .catch(() => { });
    },
    //直播封面上传成功后的回调
    async liveCoverSuccess(data) {
      if (!data.upload) return;
      const resData = data.upload.CompleteMultipartUploadResult;
      const etag = resData.ETag.replace(/"/g, "");
      const res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      this.linkParams.liveCover = resData.Location;
      this.linkParams.live_cover_id = res.data.data.id;
      this.$refs["linkForm"].validateField("live_cover_id");
    },
    //教研会议主题封面上传成功后的回调
    async meetingCoverSuccess(data) {
      if (!data.upload) return;
      const resData = data.upload.CompleteMultipartUploadResult;
      const etag = resData.ETag.replace(/"/g, "");
      const res = await this.$axios.get("/etagChangeId", {
        params: {
          etag: etag,
        },
      });
      this.linkParams.meetingCover = resData.Location;
      this.linkParams.meeting_cover_id = res.data.data.id;
      this.$refs["linkForm"].validateField("meeting_cover_id");
    },
    // //教研会议选择指定成员回调
    // meetingTearmTableChecked(list) {
    //   this.meetingTearmCheckedList = list
    //   this.meetingTearmTableChoiceNumber = this.meetingTearmCheckedList.length
    //   this.$refs["linkForm"].validateField("user_ids")
    // },
    //问卷选项加载更多
    questionnaireLoadmore() {
      if (this.questionnaireOption.length >= this.questionnaireParams.total) return;
      this.questionnaireParams.page++;
      this.getQuestionnaire();
    },
    //投票选项加载更多
    voteLoadmore() {
      if (this.voteOption.length >= this.voteParams.total) return;
      this.voteParams.page++;
      this.getVote();
    },
    //返回链接
    goBackUrl() {
      let query = {
        activityId: this.linkParams.activityId,
        is_from: this.linkParams.is_personid,
      };
      return `/basicinfo${queryString(query)}`;
    },
    //提交按钮
    submitButton() {
      this.$refs["linkForm"].validate((valid) => {
        if (valid) {
          this.postLink();
        }
      });
    },
    getApiTeachingGroupMember() {
      this.teachingGroupMemberList = [];
      if (this.activityInfo.is_personid == 1) {
        this.$axios
          .get("/TeacherGroupUser/get_group_user", {
            params: {
              limit: 99999,
              page: 1,
              group_id: this.activityInfo.group_id,
            },
          })
          .then((res) => {
            this.teachingGroupMemberList = res.data.data.data;
          });
      }
      if (this.activityInfo.is_personid == 2) {
        this.$axios
          .get("/activityLink/get_appoint_user_teachingstudio", {
            params: {
              limit: 99999,
              page: 1,
              teaching_studio_id: this.activityInfo.teaching_studio_id,
            },
          })
          .then((res) => {
            if (res.data.data.data.length > 0) {
              res.data.data.data.forEach((res) => {
                this.teachingGroupMemberList.push({
                  username: res.realname,
                  user_id: res.id,
                });
              });
            }
          });
      }
    },
    // 自动增加 3小时
    change_start(e) {
      if (!e) {
        return false;
      }
      let hour = e.split(":")[0];
      let minute = e.split(":")[1];
      hour = Number(hour) + 3;
      let end_time = hour > 23 ? "23:59" : `${hour < 10 ? "0" : ""}${hour}:${minute}`;
      this.$set(this.linkParams, "end_time", end_time);
    },
    //选择指定成员
    meetingTearmTableChecked(isOk, list) {
      if (isOk && Array.isArray(list)) {
        this.meetingTearmCheckedList = list;
        this.meetingTearmTableChoiceNumber = this.meetingTearmCheckedList.length;
      }
      this.meetingTearmTableShow = false;
      //this.$refs['activityInfoCreateForm'].validateField('permiss_users'); //单独校验
    },
    //回放设置选项变更
    playbackOptionChange() {
      this.linkParams.live_upload_time = "";
      this.$refs["linkForm"].validateField("playbackSetup");
    },
    // 上传视频
    async dcFile(data) {
      if (!data.upload) return;
      let resData = data.upload.CompleteMultipartUploadResult;
      let etag = resData.ETag.replace(/"/g, "");
      let res = await this.$axios.get("/etagChangeId", { params: { etag: etag } });
      this.resource_video_data = [
        {
          id: res.data.data.id,
          resource_name: res.data.data.name,
          url: res.data.data.url,
        },
      ];
      this.linkParams.video_resource_id = this.resource_video_data[0].id;
      this.$refs["linkForm"].validateField("timeFn3"); //单独校验
    },
    // 清除视频
    clearVideo() {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.resource_video_data = [];
        })
        .catch(() => { });
    },
  },
  watch: {},
  directives: {
    "el-select-loadmore": {
      bind(el, binding) {
        // 获取element-ui定义好的scroll盒子
        const selectwrap_dom = el.querySelector(
          ".el-select .el-select-dropdown .el-select-dropdown__wrap"
        );
        selectwrap_dom.addEventListener("scroll", function () {
          /**
           * scrollheight 获取元素内容高度(只读)
           * scrolltop 获取或者设置元素的偏移值,常用于, 计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrolltop的值默认为0.
           * clientheight 读取元素的可见高度(只读)
           * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
           * ele.scrollheight - ele.scrolltop === ele.clientheight;
           */
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
    "el-select-input": {
      bind(el, binding) {
        const selectinput = el.querySelector(".el-select .el-input .el-input__inner");
        selectinput.addEventListener("input", function (e) {
          binding.value(e.target.value);
        });
      },
    },
  },
};
</script>

<style lang="less" scoped>
.public-page-body {
  background: #eef2fb;
  padding: 25px 0 60px 0;
}

.public-card-1 {
  width: 1400px;
  background: #fff;
  border-radius: 12px;
  margin: 0 auto;

  .card-head {
    height: 70px;
    border-bottom: 1px solid #d8e0f2;
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    .card-head-title {
      padding-left: 30px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .title-arrow {
        font-size: 22px;
        color: #666666;
        margin-right: 2px;
      }

      .title-text {
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        margin-right: 6px;
      }

      .title-icon {
        width: 20px;
        height: 20px;
        background-size: 100% !important;
        display: block;
      }

      .title-icon-1 {
        background: url("../../../assets/huodon_img/资源浏览@2x.png") no-repeat center;
      }

      .title-icon-2 {
        background: url("../../../assets/huodon_img/资源上传@2x.png") no-repeat center;
      }

      .title-icon-3 {
        background: url("../../../assets/huodon_img/讨论@2x.png") no-repeat center;
      }

      .title-icon-4 {
        background: url("../../../assets/huodon_img/问卷@2x.png") no-repeat center;
      }

      .title-icon-5 {
        background: url("../../../assets/huodon_img/投票@2x.png") no-repeat center;
      }

      .title-icon-6 {
        background: url("../../../assets/huodon_img/在线文档@2x.png") no-repeat center;
      }

      .title-icon-7 {
        background: url("../../../assets/huodon_img/在线直播@2x.png") no-repeat center;
      }

      .title-icon-8 {
        background: url("../../../assets/huodon_img/教研会议@2x.png") no-repeat center;
      }
    }
  }
}

.button-confirm,
.button-cancel {
  height: 50px;
  background: #3489fe;
  border-radius: 4px;
  font-size: 22px;
  font-weight: bold;
  color: #ffffff;
  line-height: 50px;
  padding: 0 30px;
  display: inline-block;
  cursor: pointer;
}

.button-cancel {
  background: #b6becd;
}

.link-el-form {
  width: 100%;
  margin: 0;

  /deep/.permiss {
    display: flex;

    .el-form-item__content {
      display: flex;
      align-items: center;
      margin-left: 0 !important;
    }
  }

  /deep/.resourcesType-form-item {
    .el-form-item__label,
    .el-form-item__content {
      line-height: 20px;
    }
  }
}

.bottom-button-box {
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.upload-tip {
  font-size: 12px;
  color: #606266;
  margin-top: 7px;
  line-height: 22px;
}

.upload-box {
  width: 100px;
  height: 100px;
  background: #f7f7f7;
  border: 1px solid #ececec;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .upload-img {
    width: 100%;
  }

  .upload-noData {
    font-size: 30px;
  }
}

.resource-list {
  li {
    display: flex;
    align-items: center;
    line-height: 24px;
    margin-top: 8px;

    i {
      font-size: 14px;
      color: #f56c6c;
      cursor: pointer;
    }

    p {
      font-size: 14px;
      color: #333;
      flex: 1;
      overflow: hidden;
      margin-left: 15px;
    }
  }
}

.form-item-flex {
  display: flex;

  .item-flex-left {}

  .item-flex-right {
    flex: 1;
    overflow: hidden;
    display: flex;
    align-items: center;

    .item-text {
      font-size: 15px;
      color: #333;
      margin-left: 50px;

      .item-link {
        color: #409eff;
        cursor: pointer;
        margin-left: 10px;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.tearm-box {
  width: 500px;
  height: 100px;
  border: 1px solid #dcdfe6;
  display: flex;

  .box-operate {
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-right: 1px solid #dcdfe6;
  }

  .box-tag {
    flex: 1;
    padding: 15px;
    overflow-y: auto;
  }
}

.participation-box {
  display: flex;

  .box-left {
    margin-right: 15px;
  }

  .box-right {
    flex: 1;
    overflow: hidden;

    .participation-people {
      font-size: 14px;
      color: #606266;
      cursor: pointer;
      display: inline;

      span {
        color: #f56c6c;
        margin: 0 4px;
      }
    }
  }
}

.upload-box2 {
  display: flex;

  .upload-img-box {
    width: 134px;
    height: 75px;
    background: #f7f7f7;
    border: 1px solid #ececec;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    cursor: pointer;

    .upload-img {
      width: 134px;
      height: 75px;
    }

    .upload-noData {
      font-size: 30px;
    }
  }

  .upload-img-activity {
    border: 4px solid #409eff;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    &::after {
      content: "选中";
      display: block;
      font-size: 20px;
      color: #fff;
      font-weight: bold;
      position: absolute;
    }
  }

  //三张图片样式
  .box_img1 {
    .dg {
      z-index: 1;
      position: absolute;
      margin-left: -83px;
      margin-top: 18px;
      font-size: 38px;
      color: #f7f7f7;
    }

    background: #000;
    width: 134px;
    height: 75px;
    margin-right: 5px;
    //

    img {
      position: relative;
      z-index: 2;
      width: 134px;
      height: 75px;
      opacity: 0.6;
    }
  }

  // 没有打钩的样式
  .box_img2 {
    .dg {
      display: none;
    }

    width: 134px;
    height: 75px;
    margin-right: 5px;

    img {
      position: relative;
      z-index: 2;
      width: 134px;
      height: 75px;
    }
  }
}

.videoBox {
  width: 60%;
  margin-top: 5px;
  padding: 0 10px;
  background: rgba(238, 242, 251, 1);
  border-radius: 4px;

  .forItemBox {
    display: flex;

    .one {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .two {
      cursor: pointer;

      span {
        margin-left: 10px;
        color: #409eff;
      }
    }
  }
}
</style>
